import flagsmith from 'flagsmith';

import { type Session } from '@/types/session';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';
import { dateCreatedFromMongoId } from '@/utils/dateCreatedFromMongoId';
import { trackExperiment } from './analytics';

interface FlagsmithKeys {
  ab_testing_sign_up_flow: 'normal' | 'quick' | 'quick-template' | 'create_with_ai_v1';
  add_import_v1: boolean;
  albato_flows: boolean;
  onboarding_checklists_v1: 'on' | 'off';
  ab_testing_blurredsignup: 'social-proof-control' | 'blurred';
  setup_wizard_v1: 'two_cta' | 'three_cta';
}

interface TrackVariationProps {
  experimentId: string;
  experimentName: string;
  variationId: string;
  variationMapping: Record<string, string>;
  accountId: string;
  userEmail: string;
  shouldSendAnalytics: (email: string) => boolean;
}

/**
 * Get the value of a flag
 */
export const getFlagValue = <T extends keyof FlagsmithKeys>(key: T) =>
  // We are assuming the Flagsmith key follows the type defined in FlagsmithKeys for this key
  flagsmith.getValue(key) as FlagsmithKeys[T];

/**
 * Return true if the flag is enabled
 */
export const isFlagEnabled = <T extends keyof FlagsmithKeys>(key: T) => flagsmith.hasFeature(key);

/**
 * Retrieves all enabled feature flags from Flagsmith
 */
export const getAllEnabledFlags = async <T extends keyof FlagsmithKeys>(): Promise<
  Partial<Record<keyof FlagsmithKeys, FlagsmithKeys[T]>>
> => {
  // This line ensures we're up-to-date with the flags value, meaning that if we previously identified the user we
  // ensure we get computed values for that identity and not control environment values.
  await flagsmith.getFlags();

  const flagsmithFlags = flagsmith.getAllFlags();

  return Object.entries(flagsmithFlags).reduce((acc, [name, { enabled, value }]) => {
    if (enabled) {
      acc[name] = value ?? enabled;
    }
    return acc;
  }, {});
};

export const getFlagsmithTraitsForSession = (session: Session) => {
  const signUpDate = dateCreatedFromMongoId(session.account.id);

  return {
    accountSignUp: signUpDate.toISOString(),
    accountSignUpEpoch: Math.floor(signUpDate.getTime() / 1000),
    email: session.user.email
  };
};

/**
 * This function uses the localStorage and random ids to identify anonymous users in
 * Flagsmith to later obtain an identity-computed value.
 */
export const getAnonymousFlagValue = async <T extends keyof FlagsmithKeys>(key: T) => {
  if (flagsmith.identity) {
    // Ensure up-to-date state from Flagsmith
    await flagsmith.getFlags();

    return getFlagValue(key);
  }

  let id = localStorage.getItem(LOCAL_STORAGE_KEYS.anonymousFlagsId);

  if (id === null) {
    id = Math.floor(Date.now() * Math.random()).toString(16);

    localStorage.setItem(LOCAL_STORAGE_KEYS.anonymousFlagsId, id);
  }

  await flagsmith.identify(id);

  // Ensure up-to-date state from Flagsmith
  await flagsmith.getFlags();

  return getFlagValue(key);
};

export const trackVariation = async ({
  experimentId,
  experimentName,
  variationId,
  variationMapping,
  accountId,
  userEmail,
  shouldSendAnalytics
}: TrackVariationProps) => {
  if (shouldSendAnalytics(userEmail)) {
    const variationName = variationMapping[variationId] || 'Default';
    await trackExperiment(
      {
        experimentId,
        experimentName,
        variationId,
        variationName
      },
      accountId
    );
  }
};
