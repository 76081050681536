import { useTranslation } from 'react-i18next';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import { HiMiniSquaresPlus as CustomDataIcon } from 'react-icons/hi2';
import { Button, Card } from '@knack/asterisk-react';

type SetupWizardSectionProps = {
  variationId: string | null;
  handleTestOrImportData: (caseType: string) => void;
};

type SetupWizardActionCardProps = {
  icon: React.ReactElement;
  descriptionKey: string;
  buttonTextKey: string;
  handleClick: () => void;
  buttonTestId: string;
};

function SetupWizardActionCard({
  icon,
  descriptionKey,
  buttonTextKey,
  handleClick,
  buttonTestId
}: SetupWizardActionCardProps) {
  const [t] = useTranslation();

  const baseIconClassName = 'flex size-24 items-center justify-center p-6';
  const iconContainerClassName = `${baseIconClassName} ${
    icon.type === CustomDataIcon ? 'bg-brand-50' : 'bg-illustration-yellow'
  }`;

  const buttonClassName = icon.type === CustomDataIcon ? 'text-gradient-1' : '';

  return (
    <Card className="max-h-22 overflow-hidden !p-0 group-hover:bg-subtle">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className={iconContainerClassName}>{icon}</div>
          <div className="ml-4">{t(descriptionKey)}</div>
        </div>
        <div className="mr-6 flex items-center gap-2">
          <Button
            intent="link"
            size="sm"
            data-testid={buttonTestId}
            className={buttonClassName}
            onClick={(event) => {
              event.stopPropagation();
              handleClick();
            }}
          >
            {t(buttonTextKey)}
          </Button>
        </div>
      </div>
    </Card>
  );
}

export function SetupWizardSection({
  variationId,
  handleTestOrImportData
}: SetupWizardSectionProps) {
  const [t] = useTranslation();

  return (
    <>
      <span className="font-normal text-default">
        {t(`components.setup_wizard.description_${variationId}`)}
        {variationId === 'two_cta' && (
          <Button
            intent="link"
            data-testid="two-cta-button"
            className="text-gradient-1"
            onClick={() => handleTestOrImportData('scratch')}
          >
            {t('components.setup_wizard.link')}
          </Button>
        )}
      </span>
      {variationId === 'three_cta' && (
        <div className="mt-8 grid grid-cols-2 flex-row gap-4">
          <SetupWizardActionCard
            icon={<CustomDataIcon className="size-10 fill-[url(#svg-gradient-2)]" />}
            descriptionKey="components.setup_wizard.needs_unique"
            buttonTextKey="components.setup_wizard.custom_app_button"
            handleClick={() => {
              handleTestOrImportData('ai');
            }}
            buttonTestId="three-cta-scratch-button"
          />
          <SetupWizardActionCard
            icon={<DatabaseIcon className="size-10 text-yellow-600" />}
            descriptionKey="components.setup_wizard.start_my_data"
            buttonTextKey="components.setup_wizard.import_data_button"
            handleClick={() => handleTestOrImportData('import')}
            buttonTestId="three-cta-import-button"
          />
        </div>
      )}
    </>
  );
}
