export const BUILDER_IMPORT_PATH = 'schema/list/objects/add/import';
export const BUILDER_NEXT_IMPORT_PATH = 'tables/add';
export enum AppCreateType {
  Import = 'import',
  Scratch = 'scratch',
  Sample = 'template',
  Ai = 'ai',
  Auto = 'auto'
}

export enum WelcomePath {
  Ai = 'ai',
  Template = 'template'
}

export const DEFAULT_SAMPLES = [
  'quotes-and-invoices',
  'customer-portal-template',
  'custom-crm',
  'inventory-manager'
];

export const SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER = [
  'business-directory',
  'custom-crm',
  'customer-portal-template',
  'real-estate-listings',
  'volunteer-management',
  'inventory-manager',
  'project-management',
  'multi-step-application',
  'property-management',
  'warehouse-manager',
  'purchase-orders',
  'contact-directory'
];

export const TOP_SAMPLE_APPS = [
  'customer-portal-template',
  'custom-crm',
  'inventory-manager',
  'project-management',
  'contact-directory'
];

export const APPS_SKELETON_NUMBER_OF_ROWS = 13;
export const INDUSTRIES = [
  'financial_services',
  'marketing',
  'property_management',
  'software',
  'professional_services',
  'education',
  'government',
  'manufacturing',
  'retail',
  'healthcare',
  'non_profit',
  'construction'
];

export const SETUP_WIZARD_FILTERS = [
  'portals',
  'workflow',
  'directories',
  'e-commerce',
  'equations',
  'document-management',
  'project-management',
  'healthcare',
  'scheduling',
  'property-management',
  'non-profits',
  'legal',
  'construction',
  'retail',
  'software',
  'manufacturing',
  'hr',
  'education',
  'governments',
  'finance'
];
